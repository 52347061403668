import { render, staticRenderFns } from "./advert.vue?vue&type=template&id=60f28a35&scoped=true"
import script from "./advert.vue?vue&type=script&lang=js"
export * from "./advert.vue?vue&type=script&lang=js"
import style0 from "./advert.vue?vue&type=style&index=0&id=60f28a35&prod&lang=less&scoped=true"
import style1 from "./advert.vue?vue&type=style&index=1&id=60f28a35&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60f28a35",
  null
  
)

export default component.exports